import { FC, useState } from "react";
import styled, { keyframes } from "styled-components";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ReactComponent as ChevronIcon } from "../../svg/chevron-circle-right.svg";
import { ReactComponent as ChevronLeft } from "../../svg/chevron-left.svg";
import { ReactComponent as ChevronRight } from "../../svg/chevron-right.svg";
import { ArtistProps } from "../../LandingPage/RegionPage";
import parse from "html-react-parser";

interface Props {
  artists: ArtistProps[];
}

const GallerySlider: FC<Props> = ({ artists }) => {
  const [artistIndex, setArtistIndex] = useState(0);

  const onChange = (index: number) => {
    setArtistIndex(index);
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 1500,
    fade: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    nextArrow: <SvgRightArrow />,
    prevArrow: <SvgLeftArrow />,
    afterChange: onChange,
  };
  return (
    <Container {...settings}>
      {artists?.map((artist, index) => {
        return (
          <ImageContainer background={artist.images.wide}>
            <Popup href={artist.path} isVisible={index === artistIndex}>
              <div>
                <h4>Featured Artist:</h4>
                <h1>{artist.artist}</h1>
                <Subtitle isVisible={artist.subtitle ? false : true}>
                  {" "}
                  {parse(artist?.subtitle)}
                </Subtitle>
              </div>
              <Svg />
            </Popup>
          </ImageContainer>
        );
      })}
    </Container>
  );
};

export default GallerySlider;

const Container = styled(Slider)`
  background-color: grey;
  color: black;
  width: 65%;
  min-width: 65%;

  & .slick-next {
    margin-right: 35px;
    transform: scale(1.5);
  }

  & .slick-prev {
    margin-left: 35px;
    transform: scale(1.5);
    z-index: 20;
  }

  @media (max-width: 1020px) {
    width: 100%;
  }

  .slick-active {
    z-index: 20;
  }
`;

const ImageContainer = styled.div<{ background: string }>`
  background-image: url(${(props) => props.background});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: calc(100% / 5);
  padding-top: 60.25%;
  position: relative;

  &.slick-active {
    z-index: 20;
  }
`;

const animationSave = keyframes`
 0% {
      height: 0;
      margin-top: 10px;
      margin-bottom: -15px;
      padding-top: 10px;
    }
    80% {
      height: 105px;
      margin-top: -10px;
      margin-bottom: 0;
      padding-top: 10px;
    }
    100% {
      height: 95px;
      margin-top: 0;
      margin-bottom: 0;
      padding-top: 10px;
    }
`;

const animationDrown = keyframes`
    0% {
      height: 95px;
      margin-top: 0;
      margin-bottom: 0;
      padding-top: 10px;
    }
    20% {
      height: 105px;
      margin-top: -10px;
      margin-bottom: 0;
      padding-top: 10px;
    }
    100% {
      height: 0;
      margin-top: 10;
      margin-bottom: -15px;
      padding-top: 10px;
    }
`;

const SliderWrapper = styled.div`
  position: relative;
  display: flex;
`;
const Popup = styled.a<{ isVisible?: boolean }>`
  width: 100%;
  background-color: rgba(0, 9, 91, 0.7);
  color: white;
  padding: 7px 25px 5px 25px;
  text-decoration: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-transform: uppercase;

  /* opacity: ${(props) => (props.isVisible ? 1 : 0)};
  transition: opacity ease-out 0.1s; */

  /* position: absolute;
  bottom: 0;
  left: 0; */

  h4 {
    font-weight: 500;
    padding: 0;
    margin: 0 0 -8px 0;

    @media (max-width: 1020px) {
      font-size: 0.8rem;
      font-weight: 400;
      margin: 0 0 -5px 0;
    }
  }

  h1 {
    padding: 0;
    margin: 0;
    font-weight: 800;

    @media (max-width: 1020px) {
      font-size: 1.3rem;
    }
  }

  p {
    margin: -10px 0 0 0;
    font-size: 1.6rem;
    font-weight: 100;
    font-family: "AntennaLight";

    @media (max-width: 1020px) {
      font-size: 1.1rem;
      margin: -5px 0 0 0;
    }
  }

  /* animation: ${(props) =>
    props.isVisible ? animationSave : animationDrown};
  animation-delay: 0;
  animation-duration: 0.75s; */
`;

const Svg = styled(ChevronIcon)`
  width: 45px;
  height: 45px;
  display: inline-block;
  /* margin-bottom: -15px; */
  margin-right: 10px;
  padding: 0;

  @media (max-width: 1020px) {
    width: 30px;
    height: 30px;
  }
`;

const SvgLeftArrow = styled(ChevronLeft)`
  width: 50px;
  height: 50px;
  filter: drop-shadow(2px 1px 4px rgb(0 0 0 / 0.9));
  margin-top: -30px;

  @media (max-width: 1020px) {
    width: 30px;
    height: 30px;
  }
`;

const SvgRightArrow = styled(ChevronRight)`
  width: 50px;
  height: 50px;
  filter: drop-shadow(-3px 1px 4px rgb(0 0 0 / 0.9));
  margin-top: -30px;

  @media (max-width: 1020px) {
    width: 30px;
    height: 30px;
  }
`;

const Subtitle = styled.p<{ isVisible: boolean }>`
  ${(props) => props.isVisible && "padding-top: 10px"}
  span {
    font-size: 12px;
    position: relative;
    top: -10px;
  }
`;
