import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import { FirebaseProvider } from "./Components/FirebaseProvider";
import { GeoProvider } from "./Components/GeoProvider";
import LandingPage from "./LandingPage/LandingPage";
import RegionPage from "./LandingPage/RegionPage";

function App() {
  return (
    <FirebaseProvider>
      <GeoProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/:regionId" element={<RegionPage />} />
          </Routes>
        </BrowserRouter>
      </GeoProvider>
    </FirebaseProvider>
  );
}

export default App;
