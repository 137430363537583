import { FC, useEffect, useState, useContext } from "react";
import styled from "styled-components";
import GallerySlider from "../Components/Slider/Slider";
import { DropdownMenu } from "../Components/DropdownMenu/DropdownMenu";
import { ReactComponent as ChevronIcon } from "../svg/chevron-circle-right.svg";
import { ReactComponent as ChevronDown } from "../svg/duotone-chevron-down.svg";
import { collection, getFirestore, getDoc, doc } from "firebase/firestore";
import { useCollection } from "react-firebase-hooks/firestore";
import { ArtistProps, RegionConfigProps, ArtistImageProps } from "./RegionPage";
import { DateTime } from "luxon";
import { useNavigate } from "react-router-dom";

const TexasLogo =
  "https://storage.googleapis.com/whitelabel-set-live.appspot.com/fordmusic-hub/FordTexasLogo.png";
const FordLogo =
  "https://storage.googleapis.com/whitelabel-set-live.appspot.com/fordmusic-hub/Logo.png";
interface Props {
  isRegion?: boolean;
  regionName?: string;
  regionLogo?: string;
  artists?: ArtistProps[];
  latestArtist?: ArtistProps;
}

const LandingPage: FC<Props> = ({
  isRegion = false,
  artists = [],
  regionName,
  regionLogo,
  latestArtist,
}) => {
  let year = DateTime.now().year || 2024;
  const navigate = useNavigate();
  const db = getFirestore();
  const [artistList, setArtistList] = useState<ArtistProps[]>(artists);
  const [featuredArtistList, setFeaturedArtistList] =
    useState<ArtistProps[]>(artists);
  const [regionSnaps, loading] = useCollection(
    collection(db, `hub_pages/${process.env.REACT_APP_DOMAIN}/regions`)
  );

  const [isActive, setIsActive] = useState(false);

  const getHubArtists = async () => {
    const snap = await getDoc(
      doc(db, `hub_pages/${process.env.REACT_APP_DOMAIN}`)
    );
    if (snap.exists()) {
      const allArtists = snap.get("artists") as ArtistProps[];
      allArtists.reverse();
      const featuredArtists = allArtists.filter(
        (artist) => artist.featured === true
      );
      setFeaturedArtistList(featuredArtists);
      setArtistList(allArtists);
    }
  };

  // If not a region page, grab all artists from the region
  useEffect(() => {
    getHubArtists();
  }, [loading]);

  // If there's only one artist, redirect to their page
  useEffect(() => {
    if (isRegion && artistList.length === 1) {
      window.location.pathname = `${artistList[0].path}`;
    }
  }, [isRegion, artistList]);

  if (!regionSnaps || loading) {
    return null;
  }

  const onClick = () => setIsActive(!isActive);

  return (
    <MainWrapper>
      <Header onClick={() => navigate("/")}>
        <Logo
          src={regionName === "Texas" ? TexasLogo : FordLogo}
          alt="ford logo"
          region={regionName === "Texas" ? true : false}
        />
        <RegionTitle>
          <Heading region={isRegion ? true : false}>MÚSICA FORD</Heading>
          <span>{isRegion && regionName}</span>
        </RegionTitle>
      </Header>
      <Container>
        <Slider artists={latestArtist ? [latestArtist] : featuredArtistList} />
        <Aside>
          <Body region={isRegion ? true : false}>
            <Text>
              Los Concesionarios Ford Presentan Música Ford
              <p>
                Música Ford ofrece un vistazo a las vidas de los mejores
                artistas de la industria musical. Vincula sus historias
                inspiradoras con la familia de vehículos Ford, simbolizando el
                legado histórico y el espíritu de innovación.
              </p>
            </Text>
          </Body>
          <SelectContainer>
            <Text>Selecciona un área abajo y descubre nuevos sonidos.</Text>
            <DropdownMenu />
          </SelectContainer>
        </Aside>
      </Container>

      <Accordion active={isActive ? false : true}>
        <Title onClick={onClick}>
          ARTISTAS DE MÚSICA FORD
          <p>{isActive ? "CLIC PARA REDUCIR" : "CLIC PARA EXPANDIR"}</p>
          <SvgDown active={isActive ? true : false} />
        </Title>
        {isActive && (
          <Dropdown>
            <>
              {isRegion
                ? artists?.map((artist, i) => (
                    <a href={artist.path}>
                      <ArtistContainer
                        style={{
                          backgroundImage: `url(${artist.images.square})`,
                        }}
                        key={i}
                      >
                        <NameBox>
                          {artist.artist}
                          <Svg />
                        </NameBox>
                      </ArtistContainer>
                    </a>
                  ))
                : artistList?.map((artist, i) => {
                    return (
                      <a href={artist.path}>
                        <ArtistContainer
                          style={{
                            backgroundImage: `url(${artist.images.square})`,
                          }}
                          key={i}
                        >
                          <NameBox>
                            {artist.artist}
                            <Svg />
                          </NameBox>
                        </ArtistContainer>
                      </a>
                    );
                  })}
            </>
          </Dropdown>
        )}
      </Accordion>

      <Footer>
        <Disclaimer>
          © {year} Música Ford, desarrollado por{" "}
          <a
            href="https://www.max.live/"
            rel="noopener noreferrer"
            target="_blank"
          >
            Music Audience Exchange
          </a>
          . El contenido y los servicios que te proveemos en este sitio no son
          provistos por Ford Motor Company sino por Music Audience Exchange, una
          compañía independiente. Por favor ver la{" "}
          <a
            href="https://www.max.live/politica-de-privacidad-y-terminos-de-servicio/"
            rel="noopener noreferrer"
            target="_blank"
          >
            política de privacidad
          </a>{" "}
          de Music Audience Exchange.
        </Disclaimer>
        <FooterLogo src={FordLogo} alt="ford logo" />
      </Footer>
    </MainWrapper>
  );
};

export default LandingPage;

const MainWrapper = styled.div`
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
`;

const Container = styled.div`
  background-color: white;
  width: 100%;
  display: flex;
  flex-direction: row;
  @media (max-width: 1020px) {
    flex-direction: column;
  }
`;

const Aside = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 1020px) {
    flex-direction: row;
  }
  @media (max-width: 520px) {
    flex-direction: column;
  }
`;

const Body = styled.div<{ region: boolean }>`
  ${(props) =>
    props.region &&
    `background-image: url(https://storage.googleapis.com/whitelabel-set-live.appspot.com/fordmusic-hub/Ford%20Pattern_All%20Region%20Background.png); background-size: cover;
  background-position: center;
  background-repeat: no-repeat; `}
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: #00095b;
  color: white;
  padding: 40px 35px;
  @media (max-width: 1020px) {
    padding: 25px 25px 15px 25px;
  }
`;

const SelectContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 40px 35px;
  color: white;
  background-image: url("https://storage.googleapis.com/whitelabel-set-live.appspot.com/fordmusic-hub/Ford%20Pattern_Region%20Background.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  @media (max-width: 1020px) {
    padding: 25px 25px 0 25px;
  }
`;

const Slider = styled(GallerySlider)`
  display: flex;
  flex: 1;
`;

const Header = styled.div`
  background-color: white;
  display: flex;
  flex-direction: row;
  padding: 30px 70px;
  flex-direction: row;
  align-items: center;
  position: relative;
  cursor: pointer;
  @media (max-width: 1020px) {
    padding: 25px 30px 15px 30px;
    justify-content: center;
  }
`;

const Footer = styled.div`
  background-color: #fff;
  color: #00095b;
  display: flex;
  padding: 50px 100px;
  @media (max-width: 1020px) {
    padding: 30px 30px;
    align-items: center;
  }
`;

const Logo = styled.img<{ region: boolean }>`
  width: 100px;
  height: 35px;
  ${(props) => props.region && "width: 80px; height: 70px;"}
  @media (max-width: 1020px) {
    width: 70px;
    height: 30px;
    position: unset;
    margin: 0 0;
    ${(props) => props.region && "height: 60px; margin-left: 20px;"}
  }
`;

const FooterLogo = styled.img`
  width: 85px;
  height: 30px;
  @media (max-width: 1020px) {
    width: 60px;
    height: 20px;
    margin-top: -10px;
  }
`;

const Heading = styled.div<{ region: boolean }>`
  text-transform: uppercase;
  font-size: 2rem;
  ${(props) => props.region && "margin-bottom: -3px;"}
  color: #00095b;
  line-height: 100%;
  font-family: "AntennaMedium";
  @media (max-width: 1020px) {
    font-size: 1.5rem;
    margin: 0 0 10px 20px;
  }
`;

const RegionTitle = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 0 0 40px;
  span {
    color: #00095b;
    @media (max-width: 1020px) {
      margin-left: 22px;
      margin-top: -10px;
    }
  }
  @media (max-width: 1020px) {
    margin: 0;
  }
`;

const Disclaimer = styled.div`
  font-size: 0.6rem;
  color: #00095b;
  padding-right: 100px;
  a {
    color: #00095b;
  }
  @media (max-width: 1020px) {
    padding-right: 20px;
    font-size: 0.45rem;
  }
  @media (max-width: 520px) {
  }
`;

const Text = styled.div`
  font-size: min(1.8vw, 1.5rem);
  font-weight: 100;
  line-height: 130%;
  font-family: "AntennaLight";
  p {
    font-size: min(1.6vw, 1.4rem);
    @media (max-width: 1020px) {
      font-size: 0.7rem;
    }
    @media (max-width: 520px) {
      font-size: 0.8rem;
    }
  }
  @media (max-width: 1020px) {
    font-size: 0.8rem;
  }
  @media (max-width: 520px) {
    font-size: 1rem;
  }
`;

const Accordion = styled.div<{ active: boolean }>`
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
  ${(props) => props.active && "margin-bottom: 0;"}
`;

const Title = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: center;
  padding: 50px;
  font-size: 2rem;
  cursor: pointer;
  color: #00095b;
  align-items: center;
  p {
    color: grey;
    text-transform: uppercase;
    text-align: center;
    font-size: 0.8rem;
    margin: -5px 0 10px 0;
    @media (max-width: 1020px) {
      margin: 0;
    }
  }
  @media (max-width: 1020px) {
    font-size: 1.3rem;
  }
`;

const Dropdown = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  a {
    text-decoration: none;
  }
  @media (max-width: 1020px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 520px) {
    grid-template-columns: 1fr;
  }
`;

const ArtistContainer = styled.div`
  flex: 1 1 30%;
  justify-content: flex-end;
  padding-top: 90%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  cursor: pointer;
`;

const NameBox = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 7px 0 5px 10px;
  color: white;
  font-size: 1.4rem;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(0, 9, 91, 0.7);
  a {
    display: flex;
  }
`;

const Svg = styled(ChevronIcon)`
  width: 25px;
  height: 25px;
  display: inline-block;
  /* margin-bottom: -15px; */
  margin-right: 10px;
  padding: 0;
`;

const SvgDown = styled(ChevronDown)<{ active: boolean }>`
  width: 45px;
  height: 35px;
  display: inline-block;
  margin-right: 10px;
  padding: 0;
  transition: transform 0.4s ease;
  ${(props) => props.active && "transform: rotate(180deg);"}
`;
